import React from 'react';
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Stack from 'react-bootstrap/Stack';
import Card from 'react-bootstrap/Card';
import ExportExcel from "../utils/ExportExcel";
import axios from 'axios';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
} from 'chart.js';
import { Bar, Pie } from 'react-chartjs-2';
import { useAuth0 } from "@auth0/auth0-react";
import setBodyColor from '../setBodyColor'
import { DropdownButton, Dropdown, ButtonGroup } from "react-bootstrap";
import "chartjs-plugin-datalabels";
import { Link } from "react-router-dom";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  ArcElement,
  Title,
  Tooltip,
  Legend
);

const withAuth0 = (WrappedComponent) => {
  return function Auth0Component(props) {
    const { user, logout, getAccessTokenSilently } = useAuth0();
    return (
      <WrappedComponent
        {...props}
        auth0={{ user, logout, getAccessTokenSilently }}
      />
    );
  };
};

function average(array) {
  if (array !== undefined) {
      return array.reduce((a, b) => a + b) / array.length;
  }
  else {
      return 0
  }
}


function numberWithCommas(x) {
  if (typeof x !== "undefined") {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, "'");

  }
  else {
      return 0
  }
}

class Main extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      token: null,
      general_insights_api: null,
      month_parking_overview: null,
      daily_parking_overview: null,
      graph_param: 'week',
      graph_parking_usage: null,
      file: null,
      message: '',
    };
  }

  async componentDidMount() {
    if (this.props.auth0) {
      const { getAccessTokenSilently } = this.props.auth0;
      try {
        const token = await getAccessTokenSilently({
          audience: process.env.REACT_APP_AUTH0_AUDIENCE,
        });
        this.setState({ token });
        this.getGeneralInsights(token);
        this.getParkingInsights(token);
        this.getParkingUsage(token, this.state.graph_param);
      } catch (error) {
        console.error("Error getting access token:", error);
      }
    }
  }

  getDayUnixTimestamps() {
    const now = new Date();

    // Get the start of the day
    const startOfDay = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0, 0);
    const startOfDayUnix = startOfDay.getTime();

    // Get the end of the day
    const endOfDay = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 23, 59, 59, 999);
    const endOfDayUnix = endOfDay.getTime();

    return [startOfDayUnix, endOfDayUnix];
}

getMonthUnixTimestamps() {
  const now = new Date();

  // Get the start of the month
  const startOfMonth = new Date(now.getFullYear(), now.getMonth(), 1, 0, 0, 0, 0);
  const startOfMonthUnix = startOfMonth.getTime();

  // Get the end of the month
  const endOfMonth = new Date(now.getFullYear(), now.getMonth() + 1, 0, 23, 59, 59, 999);
  const endOfMonthUnix = endOfMonth.getTime();

  return [startOfMonthUnix, endOfMonthUnix];
}

  async getParkingInsights(token) {
    let daily = this.getDayUnixTimestamps();
    let monthly = this.getMonthUnixTimestamps();

    try {
      const response = await axios.get('https://api.enterprise-mobility.ch/parking_overview/'+daily[0]+'/'+daily[1], {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      this.setState({ daily_parking_overview: response.data });

      const response_2 = await axios.get('https://api.enterprise-mobility.ch/parking_overview/'+monthly[0]+'/'+monthly[1], {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      this.setState({ month_parking_overview: response_2.data });

    } catch (error) {
      console.error("Error fetching parking:", error);
    }
  }

  async getGeneralInsights(token) {
    try {
      const response = await axios.get('https://api.enterprise-mobility.ch/user_overview', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      this.setState({ general_insights_api: response.data });
    } catch (error) {
      console.error("Error fetching general insights:", error);
    }
  }

  async getParkingUsage(token, param) {
    try {
      const response = await axios.get('https://api.enterprise-mobility.ch/parking_overview/'+param, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      this.setState({ graph_parking_usage: response.data });

    } catch (error) {
      console.error("Error fetching general insights:", error);
    }
  }

  async getSelectionGraph(eventKey){
    const { getAccessTokenSilently } = this.props.auth0;

    const token = await getAccessTokenSilently({
      audience: process.env.REACT_APP_AUTH0_AUDIENCE,
    });
   this.getParkingUsage(token, eventKey);
   this.setState({graph_param: eventKey});
  }

  handleFileChange = (event) => {
    this.setState({ file: event.target.files[0] });
  };

  handleUpload = async () => {
    if (!this.state.file) {
      this.setState({ message: 'Please select a file' });
      return;
    }

    const reader = new FileReader();
    reader.readAsDataURL(this.state.file);

    reader.onload = async () => {
      const base64File = reader.result.split(',')[1];
      try {
        const response = await axios.post('https://api.enterprise-mobility.ch/sync_users', { file: base64File }, {
          headers: {
            Authorization: `Bearer ${this.state.token}`,
          },
        });

        if (response.status === 200) {
          this.setState({ message: 'File uploaded successfully!' });
        }
      } catch (error) {
        const errorMessage = error.response?.data?.error || 'Something went wrong.';
        this.setState({ message: `Error: ${errorMessage}` });
      }
    };

    reader.onerror = () => {
      this.setState({ message: 'Error reading file' });
    };
  };

  logoutWithRedirect = () => {
    const { logout } = this.props.auth0;
    logout({ returnTo: window.location.origin });
  };

  render() {
    setBodyColor({color: "#edeae6"});

    const { user } = this.props.auth0 || {};
    const ExcelExportData = [{ "PersNr": '', "Postleitzahl": '' }];
    const general_insights_api = this.state.general_insights_api || {};
    const daily_parking_overview = this.state.daily_parking_overview || {};
    const month_parking_overview = this.state.month_parking_overview || {};
    const graph_parking_usage = this.state.graph_parking_usage || [];

    const data = {
      labels: ['OSTWIND', 'ZVV-OSTWIND', 'Voucher'],
      datasets: [{
        label: '',
        data: [general_insights_api.OSTWIND, general_insights_api.ZPASS_ZVV_OSTWIND, general_insights_api.VOUCHER],
        backgroundColor: [
          'rgba(241,150,197, 0.5)',
          'rgba(153,92,254, 0.5)',
          'rgba(150,192,241, 0.5)'
        ],
        borderColor: [
          'rgb(241,150,197)',
          'rgb(153,92,254)',
          'rgb(150,192,241)'
        ],
        borderWidth: 1
      }]
    };

    const data_parking= {
      labels: graph_parking_usage.date,
      datasets: [
      {
        label: '1. Class Users',
        data: graph_parking_usage.number_class_1_users,
        backgroundColor: 'rgba(76,176,113, 0.5)',
        borderColor: 'rgb(76,176,113)',
        borderWidth: 1
      },
      {
        label: '2. Class Users',
        data: graph_parking_usage.number_class_2_users,
        backgroundColor: 'rgba(108,228,152, 0.5)',
        borderColor: 'rgb(108,228,152)',
        borderWidth: 1
      },
      {
        label: 'ParkingAbo Users',
        data: graph_parking_usage.number_parking_users,
        backgroundColor: 'rgba(229,110,109, 0.5)',
        borderColor: 'rgb(229,110,109)',
        borderWidth: 1
      },
      {
        label: 'Non-register or Undecided Users',
        data: graph_parking_usage.number_non_abo_users,
        backgroundColor: 'rgba(137,218,253, 0.5)',
        borderColor: 'rgb(137,218,253)',
        borderWidth: 1
      }
    ]
    };


    const data_pie = {
      labels: ['MobilityAbo', 'ParkingAbo', 'Undecided'],
      datasets: [{
        label: '',
        data: [general_insights_api.mobility_abo, general_insights_api.parking, (general_insights_api.activation_process_users+general_insights_api.missing_users)],
        backgroundColor: [
          'rgba(109,228,152, 0.5)',
          'rgba(229,110,109, 0.5)',
          'rgba(137,218,253, 0.5)'
        ],
        borderColor: [
          'rgb(109,228,152)',
          'rgb(229,110,109)',
          'rgb(137,218,253)'
        ],
        borderWidth: 1
      }]
    };

    const options = {
      plugins: {
        title: {
          display: true,
          text: 'MobilityAbo Type Distribution'
        },
        legend: {
          display: false
        },
      },
      responsive: true,
      maintainAspectRatio: true,
    };

    const options_parking = {
      plugins: {
        title: {
          display: false,
          text: 'Parking Usage'
        },
        legend: {
          display: true
        }
      },
      responsive: true,
      maintainAspectRatio: true,
      scales: {
        x: {
          stacked: true,
          ticks: {
            maxTicksLimit: 12
        }
        },
        y: {
          stacked: true
        }
      }
    };

    const options_pie = {
      plugins: {
        title: {
          display: true,
          text: 'Current Modal Split (Adquisition)'
        },
        legend: {
          display: true
        },
      },
      responsive: true,
      maintainAspectRatio: true,
    };

    return (
      <>
        <Navbar className="bg-body-tertiary">
          <Container>
            <Navbar.Brand>
              <img
                alt=""
                src={require("./../assets/img/logo_my_mo.png")}
                width="30"
                height="30"
                className="d-inline-block align-top"
              />{' '}
              my·mo
            </Navbar.Brand>
            <Navbar.Toggle />
            <Navbar.Collapse className="justify-content-start">
            <Link to="/main" style={{ textDecoration: 'none' }}>
            <Nav.Link className='btn_padding' disabled>Dashboard</Nav.Link>
            </Link>
            <Link to="/map-ov" style={{ textDecoration: 'none' }}>
            <Nav.Link className='btn_padding' disabled>Map ÖV</Nav.Link>
            </Link>
            <Link to="/map-parking" style={{ textDecoration: 'none' }}>
            <Nav.Link className='btn_padding' disabled>Map Parking</Nav.Link>
            </Link>
            </Navbar.Collapse>
            <Navbar.Collapse className="justify-content-end">
              <Navbar.Text>
                Welcome back, {user?.name}
              </Navbar.Text>
              <Navbar.Text><Button variant="info" className='btn_padding' onClick={this.logoutWithRedirect}>Logout</Button></Navbar.Text>
            </Navbar.Collapse>
          </Container>
        </Navbar>

        <Container className='container_main_background'>
          <Row>
            <Col>
              <Card className="customCard" style={{background: '#ff845c'}}>
                <Card.Body>
                  <Card.Title style={{fontSize: 60}}>{numberWithCommas((general_insights_api.total_registered_users+general_insights_api.missing_users))} </Card.Title>
                  <Card.Subtitle>Total Users</Card.Subtitle>
                </Card.Body>
              </Card>
            </Col>
            <Col>
              <Card className="customCard" style={{background: '#89dafd'}}>
                <Card.Body>
                  <Card.Title style={{fontSize: 60}}>{numberWithCommas((general_insights_api.activation_process_users+general_insights_api.missing_users))} </Card.Title>
                  <Card.Subtitle>Undecided Users</Card.Subtitle>
                </Card.Body>
              </Card>
            </Col>
            <Col>
              <Card className="customCard" style={{background: '#6ee598'}}>
                <Card.Body>
                  <Card.Title style={{fontSize: 60}}>{numberWithCommas(general_insights_api.mobility_abo)} </Card.Title>
                  <Card.Subtitle>MobilityAbo Users</Card.Subtitle>
                </Card.Body>
              </Card>
            </Col>
            <Col>
              <Card className="customCard" style={{background: '#e56e6e'}}>
                <Card.Body>
                  <Card.Title style={{fontSize: 60}}>{numberWithCommas(general_insights_api.parking)} </Card.Title>
                  <Card.Subtitle>ParkingAbo Users</Card.Subtitle>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>

        <Container className='container_main_background'>
        <Row>
        <Col>
        <Container className='container_main' style={{paddingLeft: '50px', paddingRight: '50px'}}>
        <h1>MobilityAbo Insights</h1>
          <Row >
            <Col sm={8}>
              <Bar data={data} options={options} />
            </Col>
            <Col sm={4}>
              <Pie data={data_pie} options={options_pie}/>
            </Col>
            </Row>
        </Container>
        </Col>
        </Row>
        </Container>

        <Container className='container_main_background'>
        <Container className='container_main' style={{paddingLeft: '50px', paddingRight: '50px'}}>
          <h1>Parking Insights</h1>
          <Row style={{paddingBottom: '40px'}}>
          <h4>Monthly Usage</h4>
          <Col>
            <Card className="customCardParking" style={{background: '#bbb6b1'}}>
                <Card.Body>
                  <Card.Title>{numberWithCommas(month_parking_overview.total_cars_entries)} </Card.Title>
                  <Card.Subtitle>Total Car Entries</Card.Subtitle>
                </Card.Body>
              </Card>
              </Col>
              <Col>
            <Card className="customCardParking" style={{background: '#ff845b'}}>
                <Card.Body>
                  <Card.Title>{numberWithCommas(month_parking_overview.total_unique_plates)} </Card.Title>
                  <Card.Subtitle>Unique Car Entries</Card.Subtitle>
                </Card.Body>
              </Card>
              </Col>
              <Col>
              <Card className="customCardParking" style={{background: '#89dafd'}}>
                <Card.Body>
                  <Card.Title>{numberWithCommas(month_parking_overview.number_non_abo_users-month_parking_overview.number_non_registered_cars)} </Card.Title>
                  <Card.Subtitle>Undecided Users</Card.Subtitle>
                </Card.Body>
              </Card>
              </Col>
              <Col>
              <Card className="customCardParking" style={{background: '#6de498'}}>
                <Card.Body>
                  <Card.Title>{numberWithCommas(month_parking_overview.number_class_1_users+ month_parking_overview.number_class_2_users)} </Card.Title>
                  <Card.Subtitle>MobilityAbo Users</Card.Subtitle>
                </Card.Body>
              </Card>
            </Col>
            <Col>
              <Card className="customCardParking" style={{background: '#e56e6d'}}>
                <Card.Body>
                  <Card.Title>{numberWithCommas(month_parking_overview.number_parking_users)} </Card.Title>
                  <Card.Subtitle>ParkingAbo Users</Card.Subtitle>
                </Card.Body>
              </Card>
            </Col>
            <Col>
              <Card className="customCardParking" style={{background: '#edeae6'}}>
                <Card.Body>
                  <Card.Title>{numberWithCommas(month_parking_overview.number_non_registered_cars)} </Card.Title>
                  <Card.Subtitle>Non-register Users</Card.Subtitle>
                </Card.Body>
              </Card>
            </Col>
            </Row>
            <Row>
            <h4>Daily Usage</h4>
            <Col>
            <Card className="customCardParking" style={{background: '#bbb6b1'}}>
                <Card.Body>
                  <Card.Title>{numberWithCommas(daily_parking_overview.total_cars_entries)} </Card.Title>
                  <Card.Subtitle>Total Car Entries</Card.Subtitle>
                </Card.Body>
              </Card>
              </Col>
              <Col>
            <Card className="customCardParking" style={{background: '#ff845b'}}>
                <Card.Body>
                  <Card.Title>{numberWithCommas(daily_parking_overview.total_unique_plates)} </Card.Title>
                  <Card.Subtitle>Unique Car Entries</Card.Subtitle>
                </Card.Body>
              </Card>
              </Col>
              <Col>
              <Card className="customCardParking" style={{background: '#89dafd'}}>
                <Card.Body>
                  <Card.Title>{numberWithCommas(daily_parking_overview.number_non_abo_users-daily_parking_overview.number_non_registered_cars)} </Card.Title>
                  <Card.Subtitle>Undecided Users</Card.Subtitle>
                </Card.Body>
              </Card>
              </Col>
              <Col>
              <Card className="customCardParking" style={{background: '#6de498'}}>
                <Card.Body>
                  <Card.Title>{numberWithCommas(daily_parking_overview.number_class_1_users+ daily_parking_overview.number_class_2_users)} </Card.Title>
                  <Card.Subtitle>MobilityAbo Users</Card.Subtitle>
                </Card.Body>
              </Card>
            </Col>
            <Col>
              <Card className="customCardParking" style={{background: '#e56e6d'}}>
                <Card.Body>
                  <Card.Title>{numberWithCommas(daily_parking_overview.number_parking_users)} </Card.Title>
                  <Card.Subtitle>ParkingAbo Users</Card.Subtitle>
                </Card.Body>
              </Card>
            </Col>
            <Col>
              <Card className="customCardParking" style={{background: '#edeae6'}}>
                <Card.Body>
                  <Card.Title>{numberWithCommas(daily_parking_overview.number_non_registered_cars)} </Card.Title>
                  <Card.Subtitle>Non-register Users</Card.Subtitle>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
        </Container>

        <Container className='container_main_background'>
        <Container className='container_main' style={{paddingLeft: '50px', paddingRight: '50px'}}>
          <Row>
<Col><h1>Parking Usage</h1></Col>
<Col style={{display:'flex', justifyContent:'flex-end'}}>
<DropdownButton
            as={ButtonGroup}
            key={'Secondary'}
            id={`dropdown-variants-Secondary`}
            variant={'secondary'}
            size="md"
            title={'Last '+this.state.graph_param}
          >
            <Dropdown.Item eventKey="1" onClick={()=>this.getSelectionGraph('week')}>Last week</Dropdown.Item>
            <Dropdown.Item eventKey="2" onClick={()=>this.getSelectionGraph('month')}>Last month</Dropdown.Item>
            <Dropdown.Item eventKey="2" onClick={()=>this.getSelectionGraph('year')}>Last year</Dropdown.Item>

          </DropdownButton></Col>
          </Row>
          <Bar data={data_parking} options={options_parking}/>
          </Container>
        </Container>

        <Container className='container_main_background'>
          <Row>
            <Col>
              <Card className="customCard" style={{background: '#89dafd'}}>
                <Card.Body>
                  <Card.Title style={{fontSize: 60}}>{average(graph_parking_usage.number_non_abo_users)?.toFixed(1)} </Card.Title>
                  <Card.Subtitle>Undecided</Card.Subtitle>
                  <Card.Text>Average users parking per day</Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col>
              <Card className="customCard" style={{background: '#6ee598'}}>
                <Card.Body>
                  <Card.Title style={{fontSize: 60}}>{average(graph_parking_usage.number_class_2_users)?.toFixed(1)} </Card.Title>
                  <Card.Subtitle>MobilityAbo</Card.Subtitle>
                  <Card.Text>Average users parking per day</Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col>
              <Card className="customCard" style={{background: '#e56e6e'}}>
                <Card.Body>
                  <Card.Title style={{fontSize: 60}}>{average(graph_parking_usage.number_parking_users)?.toFixed(1)} </Card.Title>
                  <Card.Subtitle>ParkingAbo</Card.Subtitle>
                  <Card.Text>Average users parking per day</Card.Text>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>

        <Container className='container_main_background'>
        <Container className='container_main' style={{paddingLeft: '50px', paddingRight: '50px'}}>
          <h1>Upload Excel File </h1>
          <Stack direction="horizontal" gap={3}>
            <div className="p-2">
              <Form.Group controlId="formFile" className="mb-3">
                <Form.Label>Please upload your HR Excel Data</Form.Label>
                <Form.Control type="file" accept=".xlsx,.xls,.csv" onChange={this.handleFileChange} />
              </Form.Group>
            </div>
            <div className="p-2 ms-auto">
              <Button variant="outline-secondary" id="button-addon2" onClick={this.handleUpload}>
                Upload file
              </Button>
            </div>
            <div>
              <ExportExcel excelData={ExcelExportData} fileName={'HR Data - Excel Export Template'} />
            </div>
          </Stack>
          {this.state.message && <div className="mt-3 alert alert-info">{this.state.message}</div>}
        </Container>
        </Container>
      </>
    );
  }
}

export default withAuth0(Main);
