import React from 'react';
import {StrictMode} from 'react';
import {createRoot} from 'react-dom/client';
import './css/App.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Auth0Provider } from "@auth0/auth0-react";

const rootElement = 
document.getElementById('root');
const root = createRoot(rootElement);

root.render(
  <StrictMode>
    <Auth0Provider
      domain={process.env.REACT_APP_AUTH0_DOMAIN}
      clientId={process.env.REACT_APP_AUTH0_ACCESS_TOKEN}
      redirectUri={window.location.origin}
      organization={process.env.REACT_APP_AUTH0_ORG_ID}
      audience={process.env.REACT_APP_AUTH0_AUDIENCE}
      useRefreshTokens={true}
    >
      <App />
    </Auth0Provider>
  </StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
