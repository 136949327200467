// src/components/Login.js
import React from 'react';
import Image from 'react-bootstrap/Image';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

const AccessDenied = () => {
  return(<>
    <div className="home-div">
      <div className="home-div-inner">

      <Container>
    <Row className="justify-content-md-center">
      <Col md="auto"><Image className='home-img' src={require("./../assets/img/banner-access-denied.png")} fluid/></Col>
    </Row>
  </Container>
              </div>
    </div>
    
    </>);
};

export default AccessDenied;
