// src/components/Login.js
import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';

const Login = () => {
  const { loginWithRedirect } = useAuth0();
  const location = useLocation();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const invitation = params.get('invitation');
    const organization = params.get('organization');

    if (invitation && organization) {
      loginWithRedirect({
        invitation,
        organization
      });
    } else {
      loginWithRedirect();
    }
  }, [location, loginWithRedirect]);

  return <div>Redirecting...</div>;
};

export default Login;
