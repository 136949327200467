import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import LoginButton from './components/LoginButton';
import { Navigate } from "react-router-dom";
import { useNavigate } from 'react-router-dom';

import Image from 'react-bootstrap/Image';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Navbar from 'react-bootstrap/Navbar';

import '../css/App.css'

const Home = () => {
  const { isAuthenticated, error } = useAuth0();

  if (isAuthenticated) {
    return <Navigate to="/main"/>;
  }else if (error) {
    return <Navigate to='/access-denied' />;
  }else{
    return (
      <>
      
        <Navbar className="bg-body-tertiary">
        <Container>
          <Navbar.Brand>
            <img
              alt=""
              src={require("./../assets/img/logo_my_mo.png")}
              width="30"
              height="30"
              className="d-inline-block align-top"
            />{' '}
            my·mo
          </Navbar.Brand>
          <LoginButton />
        </Container>
      </Navbar>

        <Container>
      <Row className="justify-content-md-center">
        
        <Col md="auto"><Image className='home-img' src={require("./../assets/img/my-mo-dashboard.png")} fluid/></Col>
        
      </Row>
      <Row>
        <Col>
        </Col>
      </Row>
    </Container>

      
      </>
    );
  }
};

export default Home;